
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91_46_46_46slug_93KYpJcB8WdyMeta } from "/builds/doen/multisite/doen-frontend/pages/[...slug].vue?macro=true";
import { default as indexlkhzxKNJy7Meta } from "/builds/doen/multisite/doen-frontend/pages/index.vue?macro=true";
import { default as _91slug_93a7C4GrObzQMeta } from "/builds/doen/multisite/doen-frontend/pages/jaarverslagen/[slug].vue?macro=true";
import { default as _91_46_46_46slug_93Z0CoP7jkFEMeta } from "/builds/doen/multisite/doen-frontend/pages/nieuws/[...slug].vue?macro=true";
import { default as _91_46_46_46slug_93aSNw3G7pXkMeta } from "/builds/doen/multisite/doen-frontend/pages/portfolio/[...slug].vue?macro=true";
import { default as _91_46_46_46slug_93IRKYTiwOm1Meta } from "/builds/doen/multisite/doen-frontend/pages/projecten/[...slug].vue?macro=true";
import { default as indexkT9Iq7x0MYMeta } from "/builds/doen/multisite/doen-frontend/pages/quickscan/index.vue?macro=true";
import { default as _91slug_93y7XVI5OcSoMeta } from "/builds/doen/multisite/doen-frontend/pages/wat-we-doen/[program]/[slug].vue?macro=true";
import { default as indexVrQWOKq704Meta } from "/builds/doen/multisite/doen-frontend/pages/wat-we-doen/[program]/index.vue?macro=true";
import { default as zoekcGsS8QBCiKMeta } from "/builds/doen/multisite/doen-frontend/pages/zoek.vue?macro=true";
export default [
  {
    name: "slug___en",
    path: "/en/:slug(.*)*",
    component: () => import("/builds/doen/multisite/doen-frontend/pages/[...slug].vue")
  },
  {
    name: "slug___nl",
    path: "/:slug(.*)*",
    component: () => import("/builds/doen/multisite/doen-frontend/pages/[...slug].vue")
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/builds/doen/multisite/doen-frontend/pages/index.vue")
  },
  {
    name: "index___nl",
    path: "/",
    component: () => import("/builds/doen/multisite/doen-frontend/pages/index.vue")
  },
  {
    name: "jaarverslagen-slug___en",
    path: "/en/annual-reports/:slug()",
    meta: _91slug_93a7C4GrObzQMeta || {},
    component: () => import("/builds/doen/multisite/doen-frontend/pages/jaarverslagen/[slug].vue")
  },
  {
    name: "jaarverslagen-slug___nl",
    path: "/jaarverslagen/:slug()",
    meta: _91slug_93a7C4GrObzQMeta || {},
    component: () => import("/builds/doen/multisite/doen-frontend/pages/jaarverslagen/[slug].vue")
  },
  {
    name: "nieuws-slug___en",
    path: "/en/news/:slug(.*)*",
    component: () => import("/builds/doen/multisite/doen-frontend/pages/nieuws/[...slug].vue")
  },
  {
    name: "nieuws-slug___nl",
    path: "/nieuws/:slug(.*)*",
    component: () => import("/builds/doen/multisite/doen-frontend/pages/nieuws/[...slug].vue")
  },
  {
    name: "portfolio-slug___en",
    path: "/en/portfolio/:slug(.*)*",
    component: () => import("/builds/doen/multisite/doen-frontend/pages/portfolio/[...slug].vue")
  },
  {
    name: "portfolio-slug___nl",
    path: "/portfolio/:slug(.*)*",
    component: () => import("/builds/doen/multisite/doen-frontend/pages/portfolio/[...slug].vue")
  },
  {
    name: "projecten-slug___en",
    path: "/en/projects/:slug(.*)*",
    component: () => import("/builds/doen/multisite/doen-frontend/pages/projecten/[...slug].vue")
  },
  {
    name: "projecten-slug___nl",
    path: "/projecten/:slug(.*)*",
    component: () => import("/builds/doen/multisite/doen-frontend/pages/projecten/[...slug].vue")
  },
  {
    name: "quickscan___en",
    path: "/en/quickscan",
    meta: indexkT9Iq7x0MYMeta || {},
    component: () => import("/builds/doen/multisite/doen-frontend/pages/quickscan/index.vue")
  },
  {
    name: "quickscan___nl",
    path: "/quickscan",
    meta: indexkT9Iq7x0MYMeta || {},
    component: () => import("/builds/doen/multisite/doen-frontend/pages/quickscan/index.vue")
  },
  {
    name: "wat-we-doen-program-slug___en",
    path: "/en/what-we-do/:program()/:slug()",
    component: () => import("/builds/doen/multisite/doen-frontend/pages/wat-we-doen/[program]/[slug].vue")
  },
  {
    name: "wat-we-doen-program-slug___nl",
    path: "/wat-we-doen/:program()/:slug()",
    component: () => import("/builds/doen/multisite/doen-frontend/pages/wat-we-doen/[program]/[slug].vue")
  },
  {
    name: "wat-we-doen-program___en",
    path: "/en/what-we-do/:program()",
    component: () => import("/builds/doen/multisite/doen-frontend/pages/wat-we-doen/[program]/index.vue")
  },
  {
    name: "wat-we-doen-program___nl",
    path: "/wat-we-doen/:program()",
    component: () => import("/builds/doen/multisite/doen-frontend/pages/wat-we-doen/[program]/index.vue")
  },
  {
    name: "zoek___en",
    path: "/en/search",
    meta: zoekcGsS8QBCiKMeta || {},
    component: () => import("/builds/doen/multisite/doen-frontend/pages/zoek.vue")
  },
  {
    name: "zoek___nl",
    path: "/zoek",
    meta: zoekcGsS8QBCiKMeta || {},
    component: () => import("/builds/doen/multisite/doen-frontend/pages/zoek.vue")
  }
]